import { render, staticRenderFns } from "./overhaulReceive.vue?vue&type=template&id=c888ea36&scoped=true&"
import script from "./overhaulReceive.vue?vue&type=script&lang=js&"
export * from "./overhaulReceive.vue?vue&type=script&lang=js&"
import style0 from "./overhaulReceive.vue?vue&type=style&index=0&id=c888ea36&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c888ea36",
  null
  
)

export default component.exports